:root {
    --image-height: 140px;
}

.chat-container-curai {
    width: fit-content;
    max-width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    text-align: right;
}

.chat-container-chat-visible-curai {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999999;
}

.chatbot-notification-curai {
    position: fixed;
    bottom: calc(var(--image-height) + 30px);
    right: 0;
    z-index: 999999;
}

@media screen and (min-width: 430px) {
    .chat-container-chat-visible-curai {
        right: 20px;
        bottom: 20px;
    }

    .chatbot-notification-curai {
        right: 20px;
    }
}

.chat-fonts {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

.chat-container-curai div {
    box-sizing: border-box;
}

.chat-container-curai button {
    cursor: pointer;
}

.chat-container-curai input,
.chat-container-curai textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

@media screen and (min-width: 430px) {
    .chat-container-curai {
        right: 20px;
        bottom: 20px;
    }
}

.chat-window {
    height: 600px;
    width: 400px;
    overflow-y: scroll;
}

.loading-spinner {
    position: relative;
    bottom: 3px;
}

.curai-bubble-shadow {
    box-shadow: 0px 2px 8px 2px rgba(35, 47, 53, 0.25);
}

button.curai-skip-link.curai-sr-only:focus {
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    font-size: 1rem;
    z-index: 9999;
    display: flex;
    justify-content: center;
    text-decoration: none;
    font-weight: 700;
    font-size: 14px;
    background: none;
    text-decoration: underline;
    margin-top: 10px;
}

.chat-container-curai button,
.chat-bot-wrapper-curai button {
    cursor: pointer;
}

/* Markdown styles */

.prose-curatu p {
  margin-top: 0;
  margin-bottom: 15px;
}

.prose-curatu ol li {
  margin-bottom: 10px;
}

.prose-curatu ol {
  counter-reset: list-counter;
}

/* .prose-curatu ol li {
  counter-increment: list-counter;
  position: relative;
  margin-left: 20px;
}

.prose-curatu ol li:before {
  content: counter(list-counter) ".";
  position: absolute;
  left: -20px;
} */

.prose-curatu a {
  text-decoration: underline;
}

.prose-curatu a:hover {
  color: #7834bc;
}

.prose-curatu ul {
  list-style-type: disc;
}

.prose-curatu ul li {
  margin-left: 15px;
  margin-bottom: 5px;
  line-height: 22px;
}

.prose-curatu ul li p {
  margin-bottom: 5px;
}

.chat-history p {
  line-height: 22px;
}

.chat-history {
  overflow-x: hidden;
  overflow-y: auto;
}

.prose-curatu p:last-of-type {
  margin-bottom: 0;
}